<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/rechargeRecord">充值流水</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline" label-width="100px">
      <el-row>
        <el-col :span="6">
          <el-form-item label="园区" prop="areaId">
            <el-select
              size="mini"
              v-model="queryModel.areaId"
              filterable
              placeholder="请选择"
              @change="changeArea"
            >
              <el-option
                v-for="area in queryAreaResult"
                :key="area.id"
                :label="area.name"
                :value="area.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="楼栋" prop="buildingId">
            <el-select
              size="mini"
              v-model="queryModel.buildingId"
              filterable
              placeholder="请选择"
              @change="changeBuilding"
            >
              <el-option
                v-for="building in queryBuildingResult"
                :key="building.id"
                :label="building.name"
                :value="building.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="房间" prop="roomsId">
            <el-select
              size="mini"
              v-model="queryModel.roomsId"
              filterable
              placeholder="请选择"
              @change="changeRoom"
            >
              <el-option
                v-for="rooms in queryRoomResult"
                :key="rooms.id"
                :label="rooms.name"
                :value="rooms.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button
              type="primary"
              size="mini"
              icon="ios-search"
              @click="changePage(1)"
              :loading="loading"
            >查询</el-button>&nbsp;
            <el-button
              type="info"
              size="mini"
              style="margin-left: 8px"
              @click="handleReset('queryForm')"
            >重置</el-button>&nbsp;
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="交易流水号" prop="serialNumber">
            <el-input type="text" size="mini" v-model="queryModel.serialNumber" style="width:190px;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="支付类型" prop="buyType" placeholder="请选择">
            <el-select size="mini" v-model="queryModel.buyType">
              <el-option value="alipay" label="支付宝" />
              <el-option value="weipay" label="微信" />
              <el-option value="cash" label="线下支付" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="支付状态" prop="paymentStatus" placeholder="请选择">
            <el-select size="mini" v-model="queryModel.paymentStatus">
              <el-option value="10" label="未支付"/>
              <el-option value="20" label="已支付" />
              <el-option value="30" label="支付失败" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group"></el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        prop="serialNumber"
        sort-by="serial_number"
        label="流水号"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column prop="area.name" label="园区" sortable="custom" width="120"></el-table-column>
      <el-table-column prop="building.name" label="楼栋" sortable="custom" width="120"></el-table-column>
      <el-table-column prop="room.name" label="房间" sortable="custom" width="120"></el-table-column>
      <el-table-column
        prop="dumpEnergy"
        sort-by="dump_energy"
        label="剩余水量"
        sortable="custom"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="buyElectricity"
        sort-by="buy_electricity"
        label="充值水量/升"
        sortable="custom"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="productTheme"
        sort-by="product_theme"
        label="购买类型"
        sortable="custom"
        width="120"
      ></el-table-column>
      <el-table-column prop="buyType" sort-by="buy_type" label="支付类型" sortable="custom" width="120">
        <template slot-scope="scope">
          <p v-if="scope.row.buyType=='alipay'">支付宝</p>
          <p v-if="scope.row.buyType=='weipay'">微信</p>
          <p v-if="scope.row.buyType=='cash'">线下支付</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="paymentStatus"
        sort-by="payment_status"
        label="支付状态"
        sortable="custom"
        width="120"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.paymentStatus=='10'">未支付</p>
          <p v-if="scope.row.paymentStatus=='20'">已支付</p>
          <p v-if="scope.row.paymentStatus=='30'">支付失败</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="chargingStatus"
        sort-by="charging_status"
        label="缴费状态"
        sortable="custom"
        width="120"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.chargingStatus=='10'">未缴费</p>
          <p v-if="scope.row.chargingStatus=='20'">已缴费</p>
          <p v-if="scope.row.chargingStatus=='30'">缴费失败</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        sort-by="create_time"
        label="创建时间"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="paymentTime"
        sort-by="payment_time"
        label="支付时间"
        sortable="custom"
        width="180"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <rechargeRecord-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></rechargeRecord-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import RechargeRecordDetail from "./rechargeRecord-detail";
import rechargeRecordWaterApi from "@/api/base/rechargeRecordWater";
import roomInfoApi from "@/api/base/roomInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        delFlag: "",
        serialNumber: "",
        buyAmount: "",
        buyElectricity: "",
        buyType: "",
        paymentStatus: "20",
        areaId: "",
        buildingId: "",
        roomsId: "",
        type:"0"
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      queryAreaResult: [],
      queryBuildingResult: [],
      queryRoomResult: []
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("createBy", self.queryModel.createBy);
      formData.append("createTime", self.queryModel.createTime);
      formData.append("updateBy", self.queryModel.updateBy);
      formData.append("updateTime", self.queryModel.updateTime);
      formData.append("delFlag", self.queryModel.delFlag);
      formData.append("serialNumber", self.queryModel.serialNumber);
      formData.append("buyAmount", self.queryModel.buyAmount);
      formData.append("buyElectricity", self.queryModel.buyElectricity);
      formData.append("buyType", self.queryModel.buyType);

      formData.append("paymentStatus", self.queryModel.paymentStatus);
      formData.append("areaId", self.queryModel.areaId);
      formData.append("buildingId", self.queryModel.buildingId);
      formData.append("roomsId", self.queryModel.roomsId);
      formData.append("type", "0");

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      rechargeRecordWaterApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          rechargeRecordWaterApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        rechargeRecordWaterApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    selectRoomList(keywords, roomType) {
      var roomData = new FormData();
      roomData.append("parentId", keywords);
      roomData.append("type", roomType);
      roomData.append("limit", 100);

      return roomInfoApi.query(roomData).then(response => {
        var jsonData = response.data;

        if (jsonData.result) {
          console.log(jsonData.data);
          if (roomType == "1") {
            this.queryAreaResult = jsonData.data;
          } else if (roomType == "2") {
            this.queryModel.building = "";
            this.queryModel.room = "";
            this.queryBuildingResult = jsonData.data;
          } else {
            this.queryModel.room = "";
            this.queryRoomResult = jsonData.data;
          }
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    querySelectArea() {
      this.queryModel.area = "";
      this.queryModel.building = "";
      this.queryModel.rooms = "";
      this.selectRoomList("", "1");
    },
    changeArea(keywords) {
      this.queryModel.rooms = "";
      this.queryRoomResult = "";
      this.queryModel.building = "";
      this.queryBuildingResult = "";
      this.selectRoomList(keywords, "2");
    },
    changeBuilding(keywords) {
      this.queryModel.rooms = "";
      this.queryRoomResult = "";
      this.selectRoomList(keywords, "3");
    },
    changeRoom(keywords) {
      this.$forceUpdate();
    }
  },
  mounted: function() {
    this.querySelectArea();
    this.changePage(1);
    this.queryModel.paymentStatus = "20";
  },
  components: {
    "rechargeRecord-detail": RechargeRecordDetail
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;

  .el-form-item{
    margin-bottom: 0px;
  }  
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>